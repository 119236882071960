<template>
  <b-container fluid>
    <!-- Session Details Modal -->
    <b-modal v-model="showModelSessionDetails" size="md" title="Session Details" cancel-title="Close">
      <iq-card v-for="(sesObj, index) of sessionDetailsList" :key="index" className="iq-card-block iq-card-stretch iq-card-height side_border">
        <template v-slot:body>
          Student Name: {{sesObj.user_name}}<br>
          Session Id: {{sesObj.gps_id}}<br>
          Report: <b-button v-if="sesObj.report" class="btn btn actionIcon font-size-18 mr-1 mb-1 btn-secondary btn-sm" @click="openReport(sesObj.report)" title="View GPaths Report"><i class="fa fa-map-signs" aria-hidden="true"></i></b-button>
          <b-button v-if="userData.user_role =='USERROLE11111'" class="btn btn actionIcon font-size-18 mr-1 mb-1 btn-secondary btn-sm" @click="generateReport(sesObj)" title="Generate Report"><i class="fa fa-refresh" aria-hidden="true"></i></b-button>
        </template>
      </iq-card>
    </b-modal><!-- Session Details Modal -->

    <!-- SendMail -->
    <b-modal
      v-model="showModelSendMail"
      scrollable
      :title="cvSendMailModalHeader"
    >
      <SendMail :propOpenedInModal="true" :propSendMailObj="sendMailObj" @emitCloseGPathSendMailModal="emitCloseGPathSendMailModal" />
      <template #modal-footer="">
        <b-button size="sm" @click="emitCloseGPathSendMailModal()">
          {{cvbtnModalCancel}}
        </b-button>
      </template>
    </b-modal><!-- SendMail -->
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{cvCardTitle}}</h4>
          </template>
          <template v-slot:headerAction>
            <!-- <b-button class="mr-2" variant="primary"  v-if="userData.user_role == 'USERROLE11111' || userData.user_role == 'USERROLE11118'">{{cvbtnAddNew}}</b-button>
            <b-button class="mr-2" variant="primary" @click="goToAffiliateConsole" v-if="userData.user_role == 'USERROLE11111' || userData.user_role == 'USERROLE11118'">{{cvbtnMangeAffCodes}}</b-button> -->
          </template>
          <template v-slot:body>
            <b-row class="row w-100">
              <b-col sm="3" md="3" lg="3" xl="3" class="mb-3">
                <!-- Frontend Search -->
                <!-- <b-form-input
                  id="search"
                  v-model="filter"
                  type="search"
                  :placeholder="cvSearchText"></b-form-input>Frontend Search -->

                  <!--Backend Search  -->
                  <input class="form-control"
                  v-model="search_param"
                  type="search"
                  @input="searchFromBackend"
                  :placeholder="cvSearchText" /><!--Backend Search  -->
              </b-col>

            <!-- Session State Filter -->
              <b-col sm="2" md="2" lg="2" xl="2" class="mb-3">
                <select v-model="whereFilter.session_state" class="form-control" @change="setSessionState" title="session states">
                  <option value="GPath Workflow States">GPath Workflow States</option>
                  <option value = "Not Initiated">Not Initiated</option>
                  <option v-for="(sessionState, index) of GPathStateObj" :key="(index+1)" :value="index">
                    {{sessionState}}
                  </option>
                </select>
              </b-col><!-- Session State Filter -->

              <b-col sm="1" md="1" lg="1" xl="1" class="mb-3" style="display: inline-flex;">
                  <!-- Class Name filter -->
                  <select
                  v-model="whereFilter.class"
                  type="text"
                  class="form-control"
                  id="validation_degree"
                  @change="gpathListSchoolUsers">
                    <option v-for="(Class, index) of Class" :key="index+'OrgType'">{{ Class }}</option>
                  </select><!-- Class Name filter -->
              </b-col>

              <b-col sm="4" md="4" lg="4" xl="4" class="mb-3">
                <div class="dateRange">
                  <date-range-picker ref="picker" :show-dropdowns="true" class="w-100"  :locale-data="{ firstDay: 1, format: 'dd-mm-yyyy' }"  v-model="whereFilter.dateRange" @update="getgpath_sessionDateWise()"></date-range-picker>
                </div>
              </b-col>
              <b-col sm="2" md="2" lg="2" xl="2" class="mb-3">
                <b-btn variant="primary" class="Download_button ml-2 pull-right" style="width: 100px;" @click="backendCsvDownload"> CSV</b-btn>
              </b-col>
            </b-row>
            <b-row :key="currentBatchNo">
              <b-col class="container_minheight" md="12" v-if="gpathMontoringObjList && gpathMontoringObjList.length > 0">
                <b-table
                  hover
                  responsive
                  :items="gpathMontoringObjList"
                  :fields="columns"
                  :no-border-collapse="true"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :filter="filter"
                  :filter-included-fields="filterOn"
                  :current-page="currentBatchNo"
                  :per-page="dataPerPage"
                >
                <template v-slot:cell(user_name)="data">
                  <span class="pointer" @click="openUserProfile(data.item.user_id)">{{ data.item.user_name }}</span><br>
                  <span><small>
                    Id:&nbsp;{{ data.item.user_id }}&nbsp;&nbsp;<i class="fa-solid fa-copy pointer" title="Copy User Id" @click="doCopy(data.item.user_id)"></i><br>
                    Email:&nbsp;{{ data.item.user_email }}&nbsp;&nbsp;<i class="fa-solid fa-copy pointer" title="Copy User Id" @click="doCopy(data.item.user_email)"></i><br><br>
                    Class&nbsp;{{ data.item.class_name }}<br>
                    {{ data.item.org_name }}<br>
                    {{data.item.org_city ? data.item.org_city : '' }}
                    {{data.item.org_state ? ', ' + data.item.org_state : '' }}
                    {{data.item.org_country ? ', ' + data.item.org_country : '' }}<br>
                  </small></span>
                  <span class="mt-4">
                    <b-button class="btn btn actionIcon font-size-20 mr-1 mb-1 btn-secondary btn-sm" @click="openReport(data.item.report)" v-if="data.item.report" size="sm" title="View GPaths Report"><i class="fa fa-map-signs" aria-hidden="true"></i></b-button>
                    <!-- For Multiple reports  -->
                    <b-button v-if="data.item.all_reports && data.item.all_reports.includes('#')" class="btn btn actionIcon font-size-20 mr-1 mb-1 btn-secondary btn-sm" @click="openDetailsModal(data.item)" size="sm" title="Details"><i class="fa fa-info-circle" aria-hidden="true"></i></b-button>
                    <!-- For single report -->
                    <b-button v-if="data.item.all_reports" class="btn btn actionIcon font-size-20 mr-1 mb-1 btn-secondary btn-sm" @click="openDetailsModal(data.item)" size="sm" title="Details"><i class="fa fa-info-circle" aria-hidden="true"></i></b-button>
                    <b-button v-if="userData.user_role =='USERROLE11111'" class="btn btn actionIcon font-size-20 mr-1 mb-1 btn-secondary btn-sm" @click="generateReport(data.item, false)" size="sm" title="Generate Report"><i class="fa fa-refresh" aria-hidden="true"></i></b-button>
                    <b-button v-if="userData.user_role =='USERROLE11111'" class="btn btn actionIcon font-size-20 mr-1 mb-1 btn-secondary btn-sm" size="sm" @click="gpath_sendMail('MODAL', data.item)" title="Send Mail"><i class="fa fa-envelope-o"></i></b-button>
                  </span>
                </template>
                <!-- Session Info -->
                <template v-slot:cell(session_state_desc)="data">
                  <small>
                    <b-badge :style="getStateColorCode(data.item.session_state)" class="mt-2">
                      {{ GPathStateObj[data.item.session_state] ?  GPathStateObj[data.item.session_state] : "NOT INITIATED"}}
                    </b-badge><br>
                    <span v-if="data.item.gps_id">Id: {{ data.item.gps_id.slice(0, 10)+"..." }}&nbsp;&nbsp;<i class="fa-solid fa-copy pointer" title="Copy User Id" @click="doCopy(data.item.gps_id)"></i></span>
                  </small>
                </template><!-- Session Info -->

                <template v-slot:cell(pay_tid)="data">
                  <!-- Invoice -->
                  <small>
                    Inv:&nbsp;<span class="success-color" @click="openInvoice(data.item.invoice_url)">{{ data.item.pay_tid ? data.item.pay_tid : " - " }}</span>&nbsp;
                    <i v-if="data.item.invoice_url" class="fa fa-solid fa-credit-card m-0" @click="openInvoice(data.item.invoice_url)" title="Invoice"></i>
                  </small><br/>
                  <!-- Invoice -->

                  <!-- Discount Code -->
                  <small>
                    Code:&nbsp;
                    <span v-if="data.item.aff_code" style="vertical-align: middle; color: #FFF; background: #e5252c !important;">
                      {{ data.item.aff_code ? data.item.aff_code : " - " }}
                    </span>
                  </small><br/><!-- Discount Code -->

                  <!-- Counselor Name -->
                  <small>
                    Counsellor:&nbsp;
                    <span v-if="data.item.counsellor_name">{{ data.item.counsellor_name }}</span>&nbsp;
                    <i v-if="data.item.counsellor_id" class="fa fa-solid ri-user-star-fill" title="Change Lead Counselor" @click="showChangeCounsellor(data.item)"></i>
                  </small><br/><!-- Counselor Name -->
                </template>

                <!-- Assessment Date -->
                <template v-slot:cell(created_on)="data">
                  {{ data.item.created_on | dateFormat }}
                </template><!-- Assessment Date -->

                </b-table>
              </b-col>
              <b-col class="container_minheight" md="12" v-else>
                No data found
              </b-col>
            </b-row>
            <b-row v-if="showLoadMoreBtn">
              <b-col sm="12" md="12" class="my-1">
                <b-row>
                  <b-col sm="12" md="7">
                    <b-btn variant="primary" class="mb-3" @click="loadMoreData">Load More</b-btn>
                  </b-col>
                  <b-col sm="5" md="5">
                    <b-pagination
                      v-model="currentBatchNo"
                      :total-rows="totalRows"
                      :per-page="dataPerPage"
                      align="fill"
                      size="sm"
                      class="my-0"
                    ></b-pagination>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <b-toast v-model="showToast" name="Toast" :variant="toastVariant" :title="toastTitle">
      <div v-html="toastMsg"></div>
    </b-toast>
  </b-container>
</template>

<script>
import { socialvue } from "../../../config/pluginInit"
import { GPathSchoolDashboard } from "../../../FackApi/api/GPathSchoolDashboard"
import { GPathSessions } from "../../../FackApi/api/GPathSession"
import Class from "../../../FackApi/json/Class.json"
import GPathSessionListVue from "./GPathSessionList.vue"
import SendMailModules from "../../../FackApi/json/SendMailModules.json"
import SendMail from "../../../components/SendMail.vue"
import userPermission from "../../../Utils/user_permission.js"
import GPathData from "../../../FackApi/json/GPath.json"

export default {
  name: "GPathSchoolDashboard",
  components: {
    SendMail
  },
  data () {
    return {
      apiName: "gpath_list_school_users",
      cvCardTitle: "GPaths School Dashboard",
      totalRows: 1,
      currentBatchNo: 1,
      dataPerPage: 100,
      whereFilter: {
        dateRange: {
          startDate: new Date(new Date().setDate(new Date().getDate() - 365)), // From Last Year
          endDate: new Date(new Date().setDate(new Date().getDate() + 1))
        },
        session_state: "GPath Workflow States",
        search_param: "",
        class: "All"
      },
      search_param: "",
      gpathMontoringObjList: [],
      columns: [
        { label: "#", key: "id", class: "text-left align-text-top", sortable: true },
        { label: "User Name", key: "user_name", class: "text-left align-text-top w-300px", sortable: true },
        { label: "Session Info", key: "session_state_desc", class: "text-left align-text-top w-100px", sortable: true },
        { label: "Payment", key: "pay_tid", class: "text-left align-text-top", sortable: true },
        { label: "Assessment Date", key: "created_on", class: "text-left align-text-top", sortable: true }
      ],
      sortBy: "name",
      sortDesc: false,
      showLoadMoreBtn: false,
      cvSearchText: "Type to search...",
      orgId: null,
      showToast: false,
      toastMsg: null,
      toastVariant: "default",
      toastTitle: "GPaths School Dashboard",
      filter: null,
      filterOn: null,
      csvTitle: "gpaths_status",
      loadViaLoadMore: false,
      cvClassFilter: "12",
      Class: Class,
      showModelSessionDetails: false,
      sessionDetailsList: [],
      showModelSendMail: false,
      cvSendMailModalHeader: "GPaths Send Mail",
      sendMailObj: null,
      cvbtnModalCancel: "Cancel",
      GPathStateObj: {
        "1": "INITIATED",
        "2": "PERSONAL INFO COMPLETE",
        "3": "EDUCATION INFO COMPLETED",
        "4": "PAYMENT DONE",
        "5": "CAREER COMPLETED",
        "6": "SKILL COMPLETED",
        "7": "APTITUDE COMPLETED",
        "8": "EXTRACURRICULAR COMPLETED",
        "9": "ASSESSMENT COMPLETE [REPORT AWAITED]",
        "99": "REPORT GENERATED"
      }, // Anything changed here needs to be changed in GPaths Session Dashboard as well
      SendMailModules: SendMailModules
    }
  },
  computed: {
    userData () {
      return this.$store.getters["User/userData"]
    }
  },
  beforeMount () {
    if (this.userData.user_role == "USERROLE11117") {
      // @TODO Check if this school user has access to this school data
      // Allowed
      return true
    }

    // Auth Access for Current User Role
    if (!userPermission(this, this.userData, this.apiName)) {
      this.$router.back()
    }
  },
  mounted () {
    socialvue.index()
    this.orgId = this.$route.params.org_id
    this.gpathListSchoolUsers()
  },
  methods: {
    /**
      * set Set Session State
      */
    async setSessionState () {
      this.gpathListSchoolUsers()
    },
    /**
     * searchFromBackend
     */
    async searchFromBackend () {
      if (this.search_param.length <= 3) {
        return
      }

      this.currentBatchNo = 1
      this.gpathListSchoolUsers()
    },
    /**
     *  Do Copy
     */
    doCopy: function (msg) {
      var dummy = document.createElement("textarea")
      document.body.appendChild(dummy)
      dummy.value = msg
      dummy.select()
      document.execCommand("copy")
      document.body.removeChild(dummy)
    },
    /**
     * gpath_sendMail
     */
    async gpath_sendMail (type, gpathSessionObj) {
      try {
        if (type === "FULLPAGE") {
          this.$router.push("/gpath_session_edit/" + this.gpath_sessionEditObj.gps_id)
        }
        else {
          if (gpathSessionObj.source && gpathSessionObj.source.includes("CSV")) {
            this.sendMailObj = {
              user_id: gpathSessionObj.user_id,
              user_name: gpathSessionObj.user_name,
              user_email: gpathSessionObj.user_email,
              cc_email: gpathSessionObj.parent_email,
              subject: { GPATH_REPORT: this.SendMailModules.GPATH_REPORT }
            }
          }
          else {
            this.sendMailObj = {
              user_id: gpathSessionObj.user_id,
              user_name: gpathSessionObj.user_name,
              user_email: gpathSessionObj.user_email,
              cc_email: gpathSessionObj.parent_email,
              subject: {}
            }

            for (let key in this.SendMailModules) {
              if (key.includes("GPATH")) {
                this.sendMailObj.subject[key] = this.SendMailModules[key]
              }
            }
          }

          this.showModelSendMail = true
        }
      }
      catch (err) {
        console.error("Exception occurred at gpath_sendMail() and Exception:", err.message)
      }
    },
    /**
     * openReport
     */
    openReport (url) {
      window.open(url, "_blank")
    },
    /**
     * emitCloseGPathSendMailModal
     */
    async emitCloseGPathSendMailModal () {
      this.showModelSendMail = false
    },
    /**
     * openDetailsModal
     */
    openDetailsModal (sessionData) {
      console.log(sessionData)
      const sessionDetailsList = []

      let gpsIdList = sessionData.all_gps_id.split("#")
      let reportList = sessionData.all_reports.split("#")
      let stateList = sessionData.all_states.split("#")

      for (let i = 0; i < gpsIdList.length; i++) {
        let sessionObj = {}
        sessionObj.gps_id = gpsIdList[i]
        sessionObj.report = reportList[i]
        sessionObj.session_state_desc = stateList[i]
        sessionObj.user_name = sessionData.user_name
        sessionObj.user_id = sessionData.user_id
        sessionObj.user_email = sessionData.user_email

        sessionDetailsList.push(sessionObj)
      }
      this.sessionDetailsList = sessionDetailsList
      this.showModelSessionDetails = !this.showModelSessionDetails
    },
    /**
     * Generate the report
     */
    async generateReport (userData, useLambda = false) {
      this.showActionBtns = false

      const postData = {
        user_id: userData.user_id,
        user_email: userData.user_email,
        gps_id: userData.gps_id,
        showHTML: "0",
        filename: "gpaths",
        email_send_flag: "1"
      }

      if (useLambda) {
        // Generate report with lambda function
        GPathSessionListVue.gpath_session_report_generate_lambda(this, postData)
        this.toastMsg = "Your report is being generated. It can take upto 5 mins to generate your report. Please note that a copy of the report will be sent to both your registered email address and the parent's email ID shared by you for your reference"
        this.toastVariant = "primary"
        this.showToast = true
      }
      else {
        // Generate report with api service
        let reportResp = await GPathSessions.gpath_session_report_generate(this, postData)
        if (!reportResp.resp_status) {
          this.toastMsg = "Oops! Looks like you have not completed the assessment. Please re-take the assessment. In case the problem persists please reach out to us on support@gide.ai"
          this.toastVariant = "danger"
          this.showToast = true
        }
      }
    },
    /**
     * gpathListSchoolUsers
     */
    async gpathListSchoolUsers (event = null, loadViaLoadMore = false, downloadCsv = false) {
      try {
        // setting data for pagination
        this.whereFilter.currentBatchNo = this.currentBatchNo
        this.whereFilter.dataPerPage = this.dataPerPage
        this.whereFilter.search_param = this.search_param
        this.whereFilter.downloadCsv = downloadCsv

        this.whereFilter.org_id = this.orgId

        const response = await GPathSchoolDashboard.gpath_list_school_users(this, this.whereFilter)
        if (!response.resp_status) {
          this.showLoadMoreBtn = false
          this.toastMsg = response.resp_msg
          this.toastVariant = "danger"
          this.showToast = true
          return
        }

        if (downloadCsv) {
          window.open(response.resp_csv_file_url)
          return
        }

        if ((this.whereFilter.search_param.length >= 0 || this.whereFilter.class.length != 0) && !loadViaLoadMore) {
          this.gpathMontoringObjList = [ ...response.resp_data.data ]
        }
        else {
          this.gpathMontoringObjList = [ ...this.gpathMontoringObjList, ...response.resp_data.data ]
        }
        this.gpathMontoringObjList.forEach((s, index) => { s.id = index + 1 })

        this.totalRows = this.gpathMontoringObjList.length

        if (this.whereFilter.currentBatchNo > 1) {
          this.showLoadMoreBtn = true
        }

        // Adding the serial numbers:
      }
      catch (err) {
        console.error("Exception in gpathListSchoolUsers and err: ", err)
      }
    },
    /**
     * Calling Function as per whereFilter
    */
    async getgpath_sessionDateWise () {
      this.gpathListSchoolUsers()
      await this.setDateFilter()
    },
    /**
     * Setting Date Range in the LS
     */
    async setDateFilter () {
      let endDate = this.whereFilter.dateRange.endDate
      this.whereFilter.dateRange.endDate = new Date(new Date(endDate).setDate(new Date(endDate).getDate() + 1))
      let whereDateFilter = { dateRange: this.whereFilter.dateRange }
      await this.$store.commit("DateFilter/addSelectedDate", whereDateFilter)
    },
    /**
     * loadMoreData
     */
    async loadMoreData () {
      this.currentBatchNo = this.currentBatchNo + 1
      this.gpathListSchoolUsers(null, true)
    },
    /**
     * backendCsvDownload
     */
    async backendCsvDownload () {
      this.gpathListSchoolUsers(null, false, true)
    },
    /**
     * getStateColorCode
     */
    getStateColorCode (statecode) {
      if (statecode) {
        return `vertical-align: middle; color: #FFF; background: ${GPathData.StateInfoColors[statecode]} !important;`
      }
      // Session_state not found cause the user has not initialised
      return `vertical-align: middle; color: #FFF; background: #890089 !important;`
    }
  }
}
</script>

<style>
.dateRange{
  margin-right:10px;
}
</style>
